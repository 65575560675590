import { RouteLocationNormalized } from "vue-router";
import Router from "@/router";
import { ListFilters } from "@/components/ListFilters";
import { api } from "@/api-client";

export class ReservePendingDriversFilters extends ListFilters {
    driverGroups: string[] = [];

    readonly STORAGE_KEY = "ReservePendingDriversListFilters";

    map(route: RouteLocationNormalized) {
        const hasRoute = Object.keys(route.query).length > 0;
        const storage = sessionStorage.getItem(this.STORAGE_KEY);

        if (hasRoute) {
            this.driverGroups = route.query.driverGroups ? String(route.query.driverGroups).split(",") : [];
        } else if (storage) {
            const data = JSON.parse(storage);
            this.driverGroups = data.driverGroups ?? [];
        }
    }

    createQuery() {
        return {
            driverGroups: this.driverGroups.length ? this.driverGroups : undefined,
        };
    }

    async persist(router: typeof Router) {
        sessionStorage.setItem(this.STORAGE_KEY, JSON.stringify(this.createQuery()));

        await router.replace({ query: this.createQuery() });
    }

    clearAllFilters() {
        sessionStorage.removeItem(this.STORAGE_KEY);

        this.driverGroups = [];
    }

    applyOn(drivers: api.PendingDriverViewModel[]): api.PendingDriverViewModel[] {
        drivers = this.filterByDriverGroups(drivers);
        return drivers;
    }

    filterByDriverGroups(jobs: api.PendingDriverViewModel[]) {
        return jobs.filter((job) => (this.driverGroups.length ? this.driverGroups.includes(job.pendingDriverAndVehicle?.driver.group ?? "") : true));
    }
}
